/**
 * Created by jerry on 2020/03/23.
 * 产品api
 */
import * as API from '@/api/index'

export default {
    // 产品信息
    getProductSaleList: params => {
        return API.POST('api/productSale/list',params)
    },
    // 获取产品分类
    getProductType: params => {
        return API.POST('api/productCategory/all',params)
    },
    // 获取产品销售范围
    getProductSaleEntity: params => {
        return API.POST('api/productSale/productSaleEntity',params)
    },
    // 获取组织单位
    getEntityList: params => {
        return API.POST('api/entity/list',params)
    },
    // 更新产品信息
    updateProduct: params => {
        return API.POST('api/productSale/update',params)
    },
    // 获取全部产品列表
    getProductSaleAll: params => {
        return API.POST('api/productSale/all',params)
    },
    // 获取产品详细信息
    getProductDetails: params => {
      return API.POST('api/productSale/getProductDetails',params)
    },
    // 套餐卡查询产品列表
    productListAll: params => {
        return API.POST('api/stockPackageCard/productListAll',params)
      }
}
