<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="搜索">
              <el-input
                v-model="searchProductName"
                placeholder="输入套餐卡名称搜索"
                clearable
                @keyup.enter.native="handleSearch"
                @clear="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="Status" placeholder="请选择" clearable>
                <el-option v-for="item in statusList" :key="item.ID" :label="item.name" :value="item.ID"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" @click="addProductBarCodeClick" size="small" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="套餐名称"></el-table-column>
        <el-table-column prop="TotalPrice" label="套餐价"></el-table-column>
        <el-table-column prop="time" label="销售时间" width="300">
          <template slot-scope="{ row }"> {{ row.SaleStartDate }}至{{ row.SaleEndingDate }} </template>
        </el-table-column>
        <el-table-column prop="Type" label="状态"></el-table-column>
        <el-table-column prop="active" label="有效性">
          <template slot-scope="{ row }">
            {{ row.Active ? "有效" : "无效" }}
          </template>
        </el-table-column>
        <el-table-column prop="Remark" label="活动备注"></el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row, 'edit')">编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row, 'copy')">复制</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleSearch"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 新增/编辑弹窗 -->
    <el-dialog :title="isAdd ? '新增套餐' : '编辑套餐'" :visible.sync="Dialog" width="1200px" @close="CloseDialog">
      <el-tabs v-model="dialogVisibleActive">
        <el-tab-pane label="基础配置" name="1">
          <div style="height: 40vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" size="small">
                <el-form-item label="套餐名称" prop="packageName">
                  <el-input v-model="ruleForm.packageName" style="width: 220px"></el-input>
                </el-form-item>
                <el-form-item label="销售时间" prop="Time">
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    unlink-panels
                    v-model="ruleForm.Time"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="请选择时间"
                    end-placeholder="请选择时间"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="是否有效" prop="Active">
                  <el-radio v-model="ruleForm.Active" :label="true">是</el-radio>
                  <el-radio v-model="ruleForm.Active" :label="false">否</el-radio>
                </el-form-item>
                <el-form-item label="备注信息" prop="Remark">
                  <el-input style="width: 400px" type="textarea" :rows="4" placeholder="请输入备注" v-model="ruleForm.Remark"> </el-input>
                </el-form-item>
              </el-form>
            </el-scrollbar>
          </div>
        </el-tab-pane>

        <el-tab-pane label="套餐明细" name="2">
          <div style="height: 40vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-row>
                <el-col :span="15">
                  <el-button type="primary" size="small" @click="addProducts">新增产品</el-button>
                  <el-button type="danger" size="small" @click="removeMultipleProduct" :disabled="removeDisabled">删除产品</el-button>
                </el-col>
                <el-col :span="6" class="dis_flex flex_y_center">
                  套餐总价：<el-input
                    size="small"
                    v-model="ruleForm.TotalPrice"
                    v-enter-number2
                    :disabled="ruleForm.Detail.length < 1"
                    style="width: 180px"
                  ></el-input>
                </el-col>
                <el-col :span="3" class="dis_flex flex_y_center">
                  <el-button type="primary" size="small" @click="setTotalPriceTwo">平均所有价格</el-button>
                </el-col>
              </el-row>
              <el-table
                empty-text="暂无产品"
                size="small"
                class="martp_10"
                max-height="400px"
                :data="ruleForm.Detail"
                @selection-change="removeHandleChangeSelectProduct"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="产品名称" prop="ProductName">
                  <template slot-scope="scope">
                    <!-- <el-select
                      v-model="scope.row.ProductID"
                      size="small"
                      filterable
                      placeholder="选择产品"
                    >
                      <el-option
                        v-for="item in ProductList"
                        :key="item.ID"
                        :label="item.ProductName"
                        :value="item.ID"
                        :disabled="item.IsLock"
                      >
                      </el-option>
                    </el-select> -->
                    <el-select
                      v-model="scope.row.ID"
                      size="small"
                      filterable
                      clearable
                      remote
                      reserve-keyword
                      v-loadmore="loadMoreProduct"
                      placeholder="请选择产品"
                      :remote-method="searchProductListMethod"
                      @change="(val) => handleSelectProduct(val, scope.row)"
                      @focus="selectFocus"
                      :default-first-option="true"
                    >
                      <el-option v-for="item in ProductList" :key="item.ID + 'pro'" :label="item.ProductName" :value="item.ID" :disabled="item.IsLock">
                        <!-- <div class="dis_flex flex_dir_column pad_5_0">
                      <div>
                        <span>{{ item.ProductName }}</span>
                        <span class="color_gray marlt_5 font_12" v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLock" size="mini" type="warning" effect="dark">{{'盘点锁定'}}</el-tag>
                      </div>
                      <div :class="item.ID == scope.row.ID?'font_12 dis_flex  flex_x_between' : 'color_gray font_12 dis_flex flex_x_between'">
                        <span class="">{{ item.PCategoryName }}</span>
                        <span v-if="item.Specification">{{ item.Specification }}</span>
                      </div>
                    </div> -->
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="Specification" label="产品规格"></el-table-column>
                <el-table-column prop="UnitName" label="产品单位">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.UnitID"
                      @change="(val) => handleSelectProductUnit(val, scope.row)"
                      size="small"
                      filterable
                      placeholder="选择单位"
                    >
                      <el-option v-for="item in scope.row.Units" :key="item.UnitID + 'opt'" :label="item.UnitName" :value="item.UnitID"> </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="最小包装数量">
                  <template slot-scope="scope" v-if="scope.row.UnitAmount && scope.row.MinimumUnitName">
                    {{ scope.row.UnitAmount + scope.row.MinimumUnitName }}
                  </template>
                </el-table-column>
                <el-table-column prop="PurchasePrice" label="最小包装采购价"></el-table-column>
                <el-table-column prop="PackagePrice" label="套餐价">
                  <template slot-scope="scope">
                    <el-input
                      size="small"
                      :disabled="!scope.row.UnitID"
                      v-model.number="scope.row.PackagePrice"
                      @input="ComputTotalPrice(scope.row)"
                      v-enter-number2
                      type="number"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="Amount" label="数量">
                  <template slot-scope="{ row }">
                    <el-input size="small" v-model.number="row.Amount" @input="ComputTotalPrice(row)" type="number" min="1" v-enter-int></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="总价">
                  <template slot-scope="scope">
                    <!-- {{ (scope.row.PackagePrice * scope.row.Amount) | Fixed_2 }} -->
                    {{scope.row.PackageTotalPrice | Fixed_2}}
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="{ $index }">
                    <el-button size="small" type="danger" @click="removetable($index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="套餐赠送明细" name="3">
          <div style="height: 40vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-row>
                <el-col :span="17">
                  <el-button type="primary" size="small" @click="addGive">添加套餐赠送产品</el-button>
                  <el-button type="danger" size="small" @click="removeMultipleGive" :disabled="removeDisabledGive">删除产品</el-button>
                </el-col>
                <el-col :span="7" class="dis_flex flex_y_center flex_x_end padrt_20"> 赠送总价：{{ ruleForm.GivePrice | NumFormat }} </el-col>
              </el-row>
              <el-table
                empty-text="暂无产品"
                size="small"
                class="martp_10"
                max-height="400px"
                :data="ruleForm.Give"
                @selection-change="removeHandleChangeSelectGive"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="产品名称" prop="ProductName">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.ID"
                      size="small"
                      filterable
                      placeholder="选择产品"
                      clearable
                      remote
                      reserve-keyword
                      v-loadmore="loadMoreProduct"
                      :remote-method="searchProductListMethod"
                      @change="(val) => handleSelectProduct(val, scope.row)"
                      @focus="selectFocus"
                      :default-first-option="true"
                    >
                      <el-option v-for="item in ProductList" :key="item.ID" :label="item.ProductName" :value="item.ID" :disabled="item.IsLock"> </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="Specification" label="产品规格"></el-table-column>
                <el-table-column prop="UnitName" label="产品单位">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.UnitID"
                      size="small"
                      filterable
                      @change="(val) => handleSelectProductUnit(val, scope.row)"
                      placeholder="选择单位"
                    >
                      <el-option v-for="item in scope.row.Units" :key="item.UnitID + 'give'" :label="item.UnitName" :value="item.UnitID"> </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="最小包装数量">
                  <template slot-scope="scope" v-if="scope.row.UnitAmount && scope.row.MinimumUnitName">
                    {{ scope.row.UnitAmount + scope.row.MinimumUnitName }}
                  </template>
                </el-table-column>
                <el-table-column prop="PurchasePrice" label="采购价"></el-table-column>
                <el-table-column prop="Amount" label="赠送数量">
                  <template slot-scope="{ row }">
                    <el-input size="small" v-model="row.Amount" type="number" min="0" @input="ComputGivePrice" v-enter-int></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="总价">
                  <template slot-scope="{ row }">
                    {{ (row.Amount * row.PurchasePrice) | NumFormat }}
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="{ $index }">
                    <el-button size="small" type="danger" @click="removetableGive($index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane name="4">
          <span slot="label">
            活动范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </span>
          <div style="height: 40vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-tree
                ref="treeSale"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :check-strictly="true"
                :data="entityList"
                show-checkbox
                node-key="ID"
                :default-checked-keys="defaultCheckedKeysSale"
                :default-expanded-keys="defaultExpandedKeysSale"
                :props="defaultProps"
              ></el-tree>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer">
        <el-button size="small" @click="Dialog = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="followSaveClick" :loading="modalLoading" v-prevent-click>保存 </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "@/api/iBeauty/Goods/productSale";
import PackageAPI from "@/api/PSI/Product/packageConfiguration";
var Enumerable = require("linq");
export default {
  name: "packageConfiguration",
  data() {
    return {
      ratioList: "", //产品金额占比
      entityList: [],
      salesScope: [],
      defaultCheckedKeysSale: [],
      defaultExpandedKeysSale: [1],
      defaultProps: {
        children: "Child",
        label: "EntityName",
      }, // 活动范围选择配置项
      loading: false, //页面加载
      modalLoading: false, //保存按钮加载
      Dialog: false, //新增/编辑弹窗开关
      isAdd: false, //弹窗类型
      dialogVisibleActive: "1", //弹窗内tab栏
      searchProductName: "", //筛选关键字
      Status: "2", //筛选状态
      statusList: [
        { ID: "0", name: "全部" },
        { ID: "1", name: "未开始" },
        { ID: "2", name: "进行中" },
        { ID: "3", name: "已结束" },
      ],
      tableData: [],
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      ruleForm: {
        packageName: "",
        Time: [],
        Active: true,
        Remark: "",
        TotalPrice: "", //套餐总价
        GivePrice: "", //赠送总价
        Detail: [],
        Give: [],
      },
      ProductList: [], //产品列表
      ProducTotal: 0, //产品总条数
      productPageNum: 1,
      Units: [], //单位列表
      multipleProducts: [], //产品明细
      multipleGives: [], //套餐赠送产品
      removeDisabled: true, //禁用删除产品按钮
      removeDisabledGive: true,
      rules: {
        packageName: [{ required: true, message: "请输入套餐名称", trigger: "blur" }],
        Time: [{ required: true, message: "请选择时间", trigger: "change" }],
        Active: [{ required: true }],
      },
      biLi:null, // 比例
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleSearch() {
      var params = {
        Name: this.searchProductName,
        Type: this.Status,
        PageNum: this.paginations.page,
      };
      PackageAPI.list(params).then((res) => {
        if (res.StateCode == 200) {
          this.tableData = res.List;
          this.paginations.total = res.Total;
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    addProductBarCodeClick() {
      this.isAdd = true;
      this.Dialog = true;
    },
    removetable(index) {
      this.ruleForm.Detail.splice(index, 1);
      if(this.ruleForm.Detail.length == 0){
        this.ruleForm.TotalPrice = 0
      }
      this.ComputTotalPrice();
    },
    removetableGive(index) {
      this.ruleForm.Give.splice(index, 1);
      this.ComputGivePrice();
    },
    showEditDialog(row, type) {
      var that = this;
      const { ID } = row;
      this.searchProductListMethod("");
      that.isAdd = type == "copy";
      var p1 = PackageAPI.SaleEntity({ ID });
      var p2 = PackageAPI.packageCardGoods({ ID, IsLargess: false });
      var p3 = PackageAPI.packageCardGoods({ ID, IsLargess: true });
      Promise.all([p1, p2, p3]).then((res) => {
        if (res.every((item) => item.StateCode == 200)) {
          var entity = res[0].Data;
          var Goods = res[1].Data;
          var Largess = res[2].Data;
          var ruleForm = {
            ID,
            Active: row.Active,
            Time: [row.SaleStartDate, row.SaleEndingDate],
            packageName: row.Name,
            Remark: row.Remark,
            TotalPrice: Goods.reduce((price, item) => {
              return price + item.TotalPrice;
            }, 0),
            GivePrice: Largess.reduce((price, item) => {
              return price + item.TotalPrice;
            }, 0),
            Detail: Goods.map((item) => {
              return {
                Amount: item.Amount,
                ID: item.ID,
                PackagePrice: item.Price,
                PurchasePrice: item.OriginalPrice,
                PackageTotalPrice:item.Price * item.Amount,
                UnitName: item.UnitName,
                Specification: item.Specification,
                UnitID: item.UnitID,
                Units: item.Unit,
                UnitAmount: item.UnitAmount,
                MinimumUnitName: item.MinimumUnitName,
              };
            }),
            Give: Largess.map((item) => {
              return {
                Amount: item.Amount,
                ID: item.ID,
                PackagePrice: item.Price,
                PurchasePrice: item.OriginalPrice,
                UnitName: item.UnitName,
                Specification: item.Specification,
                UnitID: item.UnitID,
                Units: item.Unit,
                UnitAmount: item.UnitAmount,
                MinimumUnitName: item.MinimumUnitName,
              };
            }),
          };
          that.ruleForm = ruleForm;
          that.ruleForm.TotalPrice = that.ruleForm.TotalPrice.toFixed(2)
          that.defaultCheckedKeysSale = entity;
          that.Dialog = true;
        } else {
          that.$message.error("网络连接失败，请稍后重试");
        }
      });
    },
    //新增弹窗关闭回调
    CloseDialog() {
      this.dialogVisibleActive = "1";
      this.defaultCheckedKeysSale = [];
      this.ruleForm = {
        packageName: "",
        Time: [],
        Active: true,
        Remark: "",
        TotalPrice: "", //套餐总价
        GivePrice: "", //赠送总价
        Detail: [],
        Give: [],
      };
      this.$nextTick(() => {
        this.$refs.treeSale.setCheckedKeys([]);
        this.$refs["ruleForm"].clearValidate();
      });
    },
    //弹窗点击保存
    followSaveClick() {
      var that = this;
      var state = false;
      console.log(that.ruleForm);
      let a = 0
      that.ruleForm.Detail.forEach((item) => {
        if (item.UnitID == "") {
          state = true;
        }
        a += Number(item.PackageTotalPrice)
      });
      if (state) {
        that.$message.error("请选择产品单位！");
        return;
      }
      var b = Number(a.toFixed(2))
      console.log(a)
      if(b !== Number(this.ruleForm.TotalPrice)){
        that.$message.error("套餐卡总价不一致！");
        return;
      }

      this.$refs["ruleForm"].validate((validate) => {
        if (validate) {
          const { ruleForm } = this;
          var params = {
            Name: ruleForm.packageName,
            SaleStartDate: ruleForm.Time[0],
            SaleEndingDate: ruleForm.Time[1],
            Active: ruleForm.Active,
            Remark: ruleForm.Remark,
            PackageCardSaleEntity: this.$refs["treeSale"].getCheckedKeys(),
            Goods: ruleForm.Detail.map((item) => {
              return {
                ID: item.ID,
                Amount: item.Amount,
                Price: item.PackagePrice,
                TotalPrice: item.PackageTotalPrice,
                UnitID: item.UnitID,
                // MinimumUnitAmount:item.UnitAmount
              };
            }),
            GoodsLargess: ruleForm.Give.map((item) => {
              return {
                ID: item.ID,
                Amount: item.Amount,
                Price: item.PurchasePrice,
                TotalPrice: item.PurchasePrice * item.Amount,
                UnitID: item.UnitID,
                // MinimumUnitAmount:item.UnitAmount
              };
            }),
          };
          var totprice = params.Goods.reduce((price, item) => {
            return price + item.TotalPrice;
          }, 0);
          if (params.Goods.length == 0 || totprice == 0) {
            this.$message.warning("请添加套餐卡所属产品");
            return;
          }
          this.modalLoading = true;
          if (this.isAdd) {
            // 新增
            this.create(params);
          } else {
            // 更新
            params.ID = this.ruleForm.ID;
            this.update(params);
          }
        }
      });

    },
    //保存新增
    create(params) {
      PackageAPI.create(params)
        .then((res) => {
          if (res.StateCode == 200) {
            this.$message.success("成功");
            this.handleSearch();
            this.Dialog = false;
          } else {
            this.$message.error(res.Message);
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    //更新
    update(params) {
      PackageAPI.update(params)
        .then((res) => {
          if (res.StateCode == 200) {
            this.$message.success("成功");
            this.handleSearch();
            this.Dialog = false;
          } else {
            this.$message.error(res.Message);
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    removeHandleChangeSelectGive(selection) {
      this.multipleGives = selection;
      if (this.multipleGives.length > 0) {
        this.removeDisabledGive = false;
      } else {
        this.removeDisabledGive = true;
      }
    },
    removeHandleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    addProducts() {
      let that = this;
      // that.searchProductName = "";
      that.ruleForm.Detail.push({
        ID: "", // ID
        Amount: 1, //数量
        UnitID: "",
        PackagePrice: "",
        UnitAmount: "",
      });
      // this.ComputTotalPrice();
    },
    addGive() {
      let that = this;
      // that.searchProductName = "";
      that.ruleForm.Give.push({
        ID: "", // ID
        Amount: 1, //数量
        UnitID: "",
        PackagePrice: "",
        UnitAmount: "",
      });
      this.computeGive();
    },
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (var i = 0; i < that.ruleForm.Detail.length; i++) {
          that.multipleProducts.forEach(function (item) {
            if (that.ruleForm.Detail[i] == item) {
              that.ruleForm.Detail.splice(i, 1);
            }
          });
        }
      }
    },
    removeMultipleGive() {
      var that = this;
      if (that.multipleGives.length > 0) {
        for (var i = 0; i < that.ruleForm.Give.length; i++) {
          that.multipleGives.forEach(function (item) {
            if (that.ruleForm.Give[i] == item) {
              that.ruleForm.Give.splice(i, 1);
            }
          });
        }
      }
    },
    // 获取组织单位
    getEntityList() {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
      };
      API.getEntityList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.ProductList = [];
      that.getStorageListNetwork(query);
    },
    /**  4.6.采购入库产品列表 */
    getStorageListNetwork: function (query) {
      var that = this;
      var params = {
        PageNum: that.productPageNum,
        ProductName: query,
      };
      API.productListAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.ProducTotal = res.Total;
          that.ProductList.push.apply(that.ProductList, res.List);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.ProducTotal > that.ProductList.length) {
        that.productPageNum++;
        that.getStorageListNetwork("");
      }
    },
    /**  下拉选择产品  */
    handleSelectProduct(itemID, row) {
      var that = this;
      row.UnitID = "";
      row.MinimumUnitQuantity = "";
      row.MinimumUnitID = "";
      row.MinimumUnitName = "";
      row.Specification = "";
      row.Quantity = "";
      row.PurchasePrice = "";
      row.UnitPrice = "";
      row.PackagePrice = "";
      row.ProductID = "";
      var item = Enumerable.from(that.ProductList)
        .where(function (p) {
          return itemID == p.ID;
        })
        .toArray()[0];
      if (!item) return;
      // item.Unit.forEach((unit) => {
      //   if (unit.IsDefautSendReceive) {
      //     // row.Unit = unit;
      //     row.UnitID = unit.UnitID;
      //     row.UnitName = unit.UnitName;
      //     row.UnitAmount = unit.Amount;
      //     row.MinimumUnitQuantity = unit.Amount * row.Quantity;
      //   }
      //   if (unit.IsMinimumUnit) {
      //     row.MinimumUnitID = unit.UnitID;
      //     row.MinimumUnitName = unit.UnitName;
      //   }
      // });
      row.ID = itemID;
      row.Units = item.Unit;
      row.Specification = item.Specification;
      row.PurchasePrice = item.PurchasePrice;
      row.MinimumUnitName = item.MinimumUnitName;
    },

    /**数量或套餐价改变重新计算总价*/
    ComputTotalPrice(row) {
      if(row){
       row.PackageTotalPrice = Number(row.PackagePrice * row.Amount).toFixed(2)
      }
      // var totalePrice = 0;
      // this.ruleForm.Detail.forEach((item) => {
      //   totalePrice += item.PackagePrice * item.Amount;
      // });
      // this.ruleForm.TotalPrice = totalePrice.toFixed(2);
    },
    /**数量或套餐价改变重新计算总价*/
    ComputGivePrice() {
      var totalePrice = 0;
      this.ruleForm.Give.forEach((item) => {
        totalePrice += item.PurchasePrice * item.Amount;
      });
      this.ruleForm.GivePrice = totalePrice.toFixed(2);
    },
    computeGive() {
      this.ruleForm.GivePrice = this.ruleForm.Give.reduce((item, item1) => {
        return item + item1.PurchasePrice * item1.Amount || 0;
      }, 0);
    },
    /** 设置套餐卡现有比例  */
    // setPackageRatio() {
    //   // var ratioList = []; //算出没见商品各自占比
    //   // this.ruleForm.Detail.forEach((item) => {
    //   //   ratioList.push(( this.ruleForm.TotalPrice / (item.PurchasePrice * item.Amount)));
    //   // });
    //   // this.ratioList = ratioList;
    //   // console.log(this.ratioList)

    //   // let total = ''
    //   // this.ruleForm.Detail.forEach(item => {
    //   //   total += Number(item.PurchasePrice)*Number(item.Amount)
    //   // })
    //   // let biLi = (Number(this.ruleForm.TotalPrice) / Number(total))
    //   // this.biLi = biLi
    // },
    /**   套餐卡修改总价  */
    // setTotalPrice() {
      // var ratioList = []; //算出没见商品各自占比
      // this.ruleForm.Detail.forEach((item) => {
      //   ratioList.push(( this.ruleForm.TotalPrice / (item.PurchasePrice * item.Amount)));
      // });
      // this.ratioList = ratioList;
      // console.log(this.ratioList)

      // var price = 0
      // if(this.ruleForm.Detail.length > 1){
      //   this.ruleForm.Detail.forEach((item,index) => {
      //     if(index == this.ruleForm.Detail.length -1){
      //       return;
      //     }
      //     item.PackageTotalPrice = Number(this.ratioList[index] * item.PurchasePrice * item.Amount).toFixed(2)
      //     item.PackagePrice = Number(item.PackageTotalPrice / item.Amount).toFixed(2)

      //     price += Number(this.ratioList[index] * item.PurchasePrice * item.Amount)
      //     this.$set(this.ruleForm.Detail[index],this.ruleForm.Detail[index].PackageTotalPrice,item.PackageTotalPrice)
      //   })
      //   var j = this.ruleForm.Detail[this.ruleForm.Detail.length - 1]
      //   j.PackageTotalPrice = Number(this.ruleForm.TotalPrice - price).toFixed()
      // }else {
      //   this.ruleForm.Detail.forEach((item,index) => {
      //     item.PackageTotalPrice = Number(this.ratioList[index] * item.PurchasePrice * item.Amount).toFixed(2)
      //     item.PackagePrice = Number(item.PackageTotalPrice / item.Amount).toFixed(2)
      //     this.$set(this.ruleForm.Detail[index],this.ruleForm.Detail[index].PackageTotalPrice,item.PackageTotalPrice)
      //   })
      //   console.log('到这啦')
      // }

      // if (!Number(this.ruleForm.TotalPrice)) {
      //   this.ruleForm.TotalPrice = "";
      //   this.ruleForm.Detail.forEach((item) => {
      //     item.PackagePrice = "";
      //   });
      //   return;
      // }
      // const { ratioList } = this;
      // let price = 0;
      // this.ruleForm.Detail.forEach((item, index) => {
      //   if (index == this.ruleForm.Detail.length - 1) {
      //     return;
      //   }
      //   item.PackagePrice = parseInt((value * ratioList[index]) / item.Amount);
      //   price += parseInt((value * ratioList[index]) / item.Amount);
      // });
      // var lastgoods = this.ruleForm.Detail[this.ruleForm.Detail.length - 1];
      // lastgoods.PackagePrice = (value - price) / lastgoods.Amount.toFixed(2);
    // },
    setTotalPriceTwo(){
      var ratioList = ""; //算出商品各自占比
      let bili = 0
      this.ruleForm.Detail.forEach((item) => {
        bili += Number(item.PurchasePrice * item.Amount)
      });
      ratioList = Number(this.ruleForm.TotalPrice / bili)
      this.ratioList = ratioList
      console.log(bili)
      console.log(ratioList)
      // var price = 0
      if(this.ruleForm.Detail.length > 1){
        this.ruleForm.Detail.forEach((item,index) => {
          // if(index == this.ruleForm.Detail.length -1){
          //   return;
          // }
          item.PackageTotalPrice = Number(this.ratioList * item.PurchasePrice * item.Amount).toFixed(2)
          item.PackagePrice = Number(item.PackageTotalPrice / item.Amount).toFixed(2)

          // price += Number(this.ratioList * item.PurchasePrice * item.Amount)
          this.$set(this.ruleForm.Detail[index],this.ruleForm.Detail[index].PackageTotalPrice,item.PackageTotalPrice)
        })
        // var j = this.ruleForm.Detail[this.ruleForm.Detail.length - 1]
        // j.PackageTotalPrice = Number(this.ruleForm.TotalPrice - price).toFixed(2)
      }else {
        this.ruleForm.Detail.forEach((item,index) => {
          item.PackageTotalPrice = Number(this.ratioList * item.PurchasePrice * item.Amount).toFixed(2)
          item.PackagePrice = Number(item.PackageTotalPrice / item.Amount).toFixed(2)
          this.$set(this.ruleForm.Detail[index],this.ruleForm.Detail[index].PackageTotalPrice,item.PackageTotalPrice)
        })
      }
    },
    /**  套餐卡下拉选择单位  */
    handleSelectProductUnit(ID, row) {
      var Units = Enumerable.from(row.Units)
        .where(function (p) {
          return ID == p.UnitID;
        })
        .toArray()[0];
      if (!Units) return;
      row.UnitName = Units.UnitName;
      row.UnitAmount = Units.Amount;
      row.PackagePrice = row.PurchasePrice * Units.Amount;
      this.ComputTotalPrice();
      this.ComputGivePrice();
    },
    /** 获取焦点   */
    selectFocus() {
      this.searchProductListMethod("");
    },
  },
  mounted() {
    this.getEntityList();
    this.handleSearch();
  },
};
</script>
<style lang="scss">
.el-scrollbar_height {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>